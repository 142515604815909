import React, { Dispatch, SetStateAction } from 'react';
import Link from 'next/link';
import { browseHotelsClick, contactUsClick, giftCardClick } from '@events/globals';
import { useFeatureFlagContext } from '@context/FeatureFlagContext';
import { useEvents } from '@events/EventsProvider';
import { REFER_NAV_CLICKED } from '@constants/amplitudeEvents';

type Props = {
  linkStyle: string;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  isGuestSelected: boolean;
  isDesktop?: boolean;
};

const defaultProps = {
  isDesktop: false,
};

export default function HeaderLinksV2({
  linkStyle,
  setShowMenu,
  isGuestSelected,
  isDesktop,
}: Props) {
  const getHotelLinks = () => (
    <div className="flex flex-col space-y-8">
      <Link href="https://partners.resortpass.com/">
        <a
          className={linkStyle}
          role="link"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setShowMenu(false);
            }
          }}
          onClick={() => {
            setShowMenu(false);
          }}
          tabIndex={0}
        >
          List My Hotel
        </a>
      </Link>

      <Link href="https://partners.resortpass.com/press">
        <a
          className={linkStyle}
          role="link"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setShowMenu(false);
            }
          }}
          onClick={() => {
            setShowMenu(false);
          }}
          tabIndex={0}
        >
          Press
        </a>
      </Link>

      <Link href="https://partners.resortpass.com/contact">
        <a
          className={linkStyle}
          role="link"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setShowMenu(false);
            }
          }}
          onClick={() => {
            setShowMenu(false);
          }}
          tabIndex={0}
        >
          Help
        </a>
      </Link>
    </div>
  );

  const { referralCampaign } = useFeatureFlagContext();
  const { track } = useEvents();

  return isGuestSelected ? (
    <div className="flex items-start">
      <div className="flex flex-col space-y-8">
        <Link href="/users/my-favorites">
          <a
            className={linkStyle}
            role="link"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setShowMenu(false);
              }
            }}
            onClick={() => {
              setShowMenu(false);
            }}
            tabIndex={0}
          >
            My Favorites
          </a>
        </Link>
        {referralCampaign === 'sale' ? (
          <Link href="/refer-navmenu">
            <a
              className={linkStyle}
              role="link"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setShowMenu(false);
                  track(REFER_NAV_CLICKED, {});
                }
              }}
              onClick={() => {
                setShowMenu(false);
                track(REFER_NAV_CLICKED, {});
              }}
              tabIndex={0}
            >
              Give $20, Get $20
            </a>
          </Link>
        ) : null}

        <Link href="/browse-hotels">
          <a
            className={linkStyle}
            role="link"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                browseHotelsClick();
                setShowMenu(false);
              }
            }}
            onClick={() => {
              browseHotelsClick();
              setShowMenu(false);
            }}
            tabIndex={0}
          >
            Browse Hotels
          </a>
        </Link>

        <Link href="/giftcards">
          <a
            className={linkStyle}
            role="link"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                giftCardClick();
                setShowMenu(false);
              }
            }}
            onClick={() => {
              giftCardClick();
              setShowMenu(false);
            }}
            tabIndex={0}
          >
            Gift Cards
          </a>
        </Link>

        <Link href="https://partners.resortpass.com/about-us">
          <a
            className={`${linkStyle} d:hidden`}
            role="link"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setShowMenu(false);
              }
            }}
            onClick={() => {
              setShowMenu(false);
            }}
            tabIndex={0}
          >
            About
          </a>
        </Link>

        <Link href="https://kb.resortpass.com/support/home">
          <a
            className={`${linkStyle}`}
            role="link"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                contactUsClick();
                setShowMenu(false);
              }
            }}
            onClick={() => {
              contactUsClick();
              setShowMenu(false);
            }}
            tabIndex={0}
          >
            Help
          </a>
        </Link>
      </div>

      {isDesktop && <div className="ml-44 d:-mt-15.2">{getHotelLinks()}</div>}
    </div>
  ) : (
    getHotelLinks()
  );
}

HeaderLinksV2.defaultProps = defaultProps;
