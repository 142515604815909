/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect, useContext, SetStateAction, Dispatch } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import toggleErrorPopup from '@helpers/toggleErrorPopup';
import { useCookies } from 'react-cookie';
import {
  checkPwdForSpecialCharacters,
  checkPwdForLettersAndNumbers,
  checkPwdLength,
  checkIfStringIsValidEmail,
} from '@helpers/checkPassword';
import RoutingPath from '@constants/routingPath';
import { useRouter } from 'next/router';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { store } from '@context/store';
import { myFavoritesCreateAccount } from '@events/myFavorites';
import Spinner from '@components/common/SmartCalendar/Spinner';
import useIsMobile from '@hooks/useIsMobile';
import CheckoutFormInput from '@components/common/CheckoutForm/CheckoutFormInput';
import useAuthentication from '@hooks/useAuthentication';
import { isUser } from '@utils/user';
import facebookIcon from '../../assets/images/facebook-icon.svg';
import googleIcon from '../../assets/images/google-icon.svg';
import FormInput from '../common/FormInput';
import ButtonPrimary from '../common/ButtonPrimary';
import ButtonLogin from '../common/ButtonLogin';
import checkMarkIcon from '../../assets/images/check-mark.png';
import crossMarkIcon from '../../assets/images/cross-icon.png';
import crossMarkRedIcon from '../../assets/images/close-red-bold.svg';

type Props = {
  isLoginButtonClicked: boolean;
  setIsLoginButtonClicked: Dispatch<SetStateAction<boolean>>;
  isCheckout?: boolean;
};

const defaultProps = {
  isCheckout: false,
};

export default function SignUp({
  isLoginButtonClicked,
  setIsLoginButtonClicked,
  isCheckout = false,
}: Props) {
  const { handleProviderLogin, userRegistration } = useAuthentication();
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [isSpinnerLoading, setIsSpinnerLoading] = useState<boolean>(false);
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmed, setPasswordConfirmed] = useState<string>('');

  const [toggleEmailValidationErrors, setToggleEmailValidationErrors] = useState<boolean>(false);
  const [togglePasswordValidationErrors, setTogglePasswordValidationErrors] =
    useState<boolean>(false);
  const [togglePasswordConfirmedValidationErrors, setTogglePasswordConfirmedValidationErrors] =
    useState<boolean>(false);

  const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState<boolean>(false);

  const [showPasswordSpecs, setShowPasswordSpecs] = useState<boolean>(false);
  const [showPasswordMatchingMessage, setShowPasswordMatchingMessage] = useState<boolean>(false);
  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState<boolean>(false);
  const [shouldFormInputErrorDisplay, setShouldFormInputErrorDisplay] = useState<boolean>(false);

  const [isPasswordLengthMet, setIsPasswordLengthMet] = useState<boolean>(false);
  const [isCharCombinationMet, setIsCharCombinationMet] = useState<boolean>(false);
  const [isPassAlphanumeric, setIisPassAlphanumeric] = useState<boolean>(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(false);

  const [rememberMe, setRememberMe] = useState<boolean>(isCheckout);
  const [isEraseCookiesOnBrowserClose, setIsEraseCookiesOnBrowserClose] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['userInformation']);
  const router = useRouter();
  const { getItem } = useSessionStorage();
  const isMobile = useIsMobile();

  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const iterableEmail = getItem(sessionStorageKeys.ITERABLE_EMAIL);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventListenerOnBrowserClose = () => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      removeCookie('userInformation', { path: '/' });
    });
  };
  const handleRememberMeOnSubmit = () => {
    setRememberMe(!rememberMe);
    if (rememberMe) {
      setIsEraseCookiesOnBrowserClose(!isEraseCookiesOnBrowserClose);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const allAccountCriteriaHasPassed =
      isPasswordLengthMet &&
      !showInvalidEmailMessage &&
      isCharCombinationMet &&
      isPassAlphanumeric &&
      doPasswordsMatch;
    if (allAccountCriteriaHasPassed && username.length > 0) {
      setIsSpinnerLoading(true);
      const cartId = getItem(sessionStorageKeys.CART_ID);
      const userInfo = await userRegistration(
        {
          username,
          password,
          passwordConfirmed,
        },
        cartId,
      );
      if (!!userInfo && isUser(userInfo)) {
        setCookie('userInformation', userInfo, { path: '/' });
        if (window.friendbuyAPI) {
          if (userInfo) {
            window.friendbuyAPI.push([
              'track',
              'customer',
              {
                id: `${userInfo.id}` || '',
                email: userInfo.email || '',
                firstName: userInfo.first_name || '',
                lastName: userInfo.last_name || '',
              },
            ]);
          }
        }
        if (setIsLoginButtonClicked) {
          setIsLoginButtonClicked(false);
        }
        if (isLoginButtonClicked) {
          router.push(RoutingPath.GUEST_BILLING_DETAILS);
        }
        if (window.location.href.includes('sign-in')) {
          router.push('/users/bookings');
        }

        if (window.location.href.includes('my-favorites')) {
          myFavoritesCreateAccount();
          window.location.reload();
        }

        if (window.Iterable) {
          await window.Iterable.updateUserEmail(
            userInfo.email,
            iterableEmail || `${sessionID}@placeholder.email`,
            userInfo,
          );
        }

        setIsSpinnerLoading(false);
      } else {
        toggleErrorPopup(
          'This email is already taken. Try again with a different email.',
          dispatch,
        );
        setIsSpinnerLoading(false);
      }
    }
    if (!allAccountCriteriaHasPassed && username.length === 0) {
      setToggleEmailValidationErrors(true);
    }
    if (!allAccountCriteriaHasPassed && password.length === 0) {
      setTogglePasswordValidationErrors(true);
    }
    if (!allAccountCriteriaHasPassed && passwordConfirmed.length === 0) {
      setTogglePasswordConfirmedValidationErrors(true);
    }
  };

  useEffect(() => {
    if (window.location.href.includes('sign-in') && cookies.userInformation?.email) {
      const path = router?.query?.referrer || '/users/bookings';
      router.push(`${path}`);
    }
  }, [cookies, router]);

  useEffect(() => {
    if (username.length > 0 && !isEmailFocused && !checkIfStringIsValidEmail(username)) {
      setShowInvalidEmailMessage(true);
    } else {
      setShowInvalidEmailMessage(false);
    }

    if (isPasswordFocused || password.length > 0) {
      setIsPasswordLengthMet(checkPwdLength(password));
      setIsCharCombinationMet(checkPwdForLettersAndNumbers(password));
      setIisPassAlphanumeric(checkPwdForSpecialCharacters(password));
      setShowPasswordSpecs(true);
    }

    if (isPasswordLengthMet && isCharCombinationMet && isPassAlphanumeric) {
      setShowPasswordSpecs(false);
      setTogglePasswordValidationErrors(false);
    }

    if (passwordConfirmed.length > 0) {
      setShowPasswordMatchingMessage(true);
    } else {
      setShowPasswordMatchingMessage(false);
    }

    if (
      passwordConfirmed.length > 0 &&
      !isConfirmPasswordFocused &&
      password !== passwordConfirmed
    ) {
      setShouldFormInputErrorDisplay(true);
    } else {
      setShouldFormInputErrorDisplay(false);
    }

    if (password && passwordConfirmed && password === passwordConfirmed) {
      setDoPasswordsMatch(true);
      setTogglePasswordConfirmedValidationErrors(false);
      setTogglePasswordValidationErrors(false);
    } else {
      setDoPasswordsMatch(false);
    }

    if (!rememberMe && isEraseCookiesOnBrowserClose) {
      eventListenerOnBrowserClose();
    }
  }, [
    password,
    passwordConfirmed,
    rememberMe,
    eventListenerOnBrowserClose,
    username,
    isPasswordLengthMet,
    isCharCombinationMet,
    isPassAlphanumeric,
    doPasswordsMatch,
    isEmailFocused,
    isConfirmPasswordFocused,
    isEraseCookiesOnBrowserClose,
    isPasswordFocused,
  ]);

  useEffect(() => {
    if (isEmailFocused && toggleEmailValidationErrors) {
      setToggleEmailValidationErrors(false);
    }
    if (isPasswordFocused && togglePasswordValidationErrors) {
      setTogglePasswordValidationErrors(false);
    }
    if (isConfirmPasswordFocused && togglePasswordConfirmedValidationErrors) {
      setTogglePasswordConfirmedValidationErrors(false);
    }
  }, [
    isEmailFocused,
    isPasswordFocused,
    isConfirmPasswordFocused,
    toggleEmailValidationErrors,
    togglePasswordValidationErrors,
    togglePasswordConfirmedValidationErrors,
  ]);

  return (
    <>
      {isSpinnerLoading && !isCheckout && <Spinner />}
      {/* Email and Password login */}
      <div className="text-left">
        {isCheckout ? (
          <CheckoutFormInput
            type="email"
            id="login_id"
            label="Email Address"
            value={username}
            setValue={setUserName}
            autoComplete="email"
            error={showInvalidEmailMessage || toggleEmailValidationErrors}
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
          />
        ) : (
          <FormInput
            type="email"
            id="login_id"
            label="Email Address"
            value={username}
            setValue={setUserName}
            autoComplete="email"
            error={showInvalidEmailMessage || toggleEmailValidationErrors}
            focused={isEmailFocused}
            setFocused={setIsEmailFocused}
            handleSubmit={handleSubmit}
          />
        )}
        {(showInvalidEmailMessage || toggleEmailValidationErrors) && (
          <div className="ml-2 flex items-center mt-0.5">
            <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
            <a
              className={`ml-2 text-xs ${isCheckout && !isMobile ? 'text-white' : 'text-red-600'}`}
            >
              The email address is invalid
            </a>
          </div>
        )}
        {isCheckout ? (
          <div className="mt-2.5">
            <CheckoutFormInput
              type="password"
              id="login_pw"
              label="Password"
              value={password}
              setValue={setPassword}
              autoComplete="off"
              error={shouldFormInputErrorDisplay || togglePasswordValidationErrors}
            />
          </div>
        ) : (
          <FormInput
            type="password"
            id="login_pw"
            label="Password"
            value={password}
            setValue={setPassword}
            autoComplete="off"
            error={shouldFormInputErrorDisplay || togglePasswordValidationErrors}
            focused={isPasswordFocused}
            setFocused={setIsPasswordFocused}
            handleSubmit={handleSubmit}
          />
        )}
        {(showPasswordSpecs || togglePasswordValidationErrors) && (
          <>
            <div className="ml-2 flex items-center mt-1" style={{ marginBottom: -10 }}>
              {isPasswordLengthMet ? (
                <Image src={checkMarkIcon} alt="checkmark" height="12" width="12" />
              ) : (
                <Image
                  src={
                    (password.length > 0 && !isPasswordFocused) || togglePasswordValidationErrors
                      ? crossMarkRedIcon
                      : crossMarkIcon
                  }
                  alt="x-icon"
                  height="10"
                  width="10"
                />
              )}
              {(password.length > 0 && !isPasswordFocused && !isPasswordLengthMet) ||
              togglePasswordValidationErrors ? (
                <a
                  className={`ml-2 text-xs ${
                    isCheckout && !isMobile ? 'text-white' : 'text-red-600'
                  }`}
                >
                  Must be at least 8 characters
                </a>
              ) : (
                <a
                  className={`ml-2 text-xs ${
                    isPasswordLengthMet ? 'text-green-600' : 'text-gray-600'
                  }`}
                >
                  Must be at least 8 characters
                </a>
              )}
            </div>
            <div className="ml-2 flex items-center mt-2.5" style={{ marginBottom: -10 }}>
              {isCharCombinationMet ? (
                <Image src={checkMarkIcon} alt="checkmark" height="12" width="12" />
              ) : (
                <Image
                  src={
                    (password.length > 0 && !isPasswordFocused) || togglePasswordValidationErrors
                      ? crossMarkRedIcon
                      : crossMarkIcon
                  }
                  alt="x-icon"
                  height="10"
                  width="10"
                />
              )}
              {(password.length > 0 && !isPasswordFocused && !isCharCombinationMet) ||
              togglePasswordValidationErrors ? (
                <a
                  className={`ml-2 text-xs ${
                    isCheckout && !isMobile ? 'text-white' : 'text-red-600'
                  }`}
                >
                  Must combine letters and numbers
                </a>
              ) : (
                <a
                  className={`ml-2 text-xs ${
                    isCharCombinationMet ? 'text-green-600' : 'text-gray-600'
                  }`}
                >
                  Must combine letters and numbers
                </a>
              )}
            </div>
            <div className="ml-2 flex items-center mt-2.5">
              {isPassAlphanumeric ? (
                <Image src={checkMarkIcon} alt="checkmark" height="12" width="12" />
              ) : (
                <Image
                  src={
                    (password.length > 0 && !isPasswordFocused) || togglePasswordValidationErrors
                      ? crossMarkRedIcon
                      : crossMarkIcon
                  }
                  alt="x-icon"
                  height="10"
                  width="10"
                />
              )}
              {(password.length > 0 && !isPasswordFocused && !isPassAlphanumeric) ||
              togglePasswordValidationErrors ? (
                <a
                  className={`ml-2 text-xs ${
                    isCheckout && !isMobile ? 'text-white' : 'text-red-600'
                  }`}
                >
                  Must contain at least one special character
                </a>
              ) : (
                <a
                  className={`ml-2 text-xs ${
                    isPassAlphanumeric ? 'text-green-600' : 'text-gray-600'
                  }`}
                >
                  Must contain at least one special character
                </a>
              )}
            </div>
          </>
        )}
        {isPasswordLengthMet &&
          isPassAlphanumeric &&
          isCharCombinationMet &&
          !togglePasswordValidationErrors && (
            <div className="ml-2">
              <Image src={checkMarkIcon} alt="checkmark" height="12" width="12" />
              <a className="ml-1 text-xs text-green-600">Password meets requirements</a>
            </div>
          )}
        {isCheckout ? (
          <div className="mt-2.5">
            <CheckoutFormInput
              type="password"
              id="login_pw_confirm"
              label="Confirm Password"
              value={passwordConfirmed}
              setValue={setPasswordConfirmed}
              autoComplete="off"
              error={shouldFormInputErrorDisplay || togglePasswordConfirmedValidationErrors}
            />
          </div>
        ) : (
          <FormInput
            type="password"
            id="login_pw_confirm"
            label="Confirm Password"
            value={passwordConfirmed}
            setValue={setPasswordConfirmed}
            autoComplete="off"
            error={shouldFormInputErrorDisplay || togglePasswordConfirmedValidationErrors}
            focused={isConfirmPasswordFocused}
            setFocused={setIsConfirmPasswordFocused}
            handleSubmit={handleSubmit}
          />
        )}
        {(showPasswordMatchingMessage || togglePasswordConfirmedValidationErrors) && (
          <div className="ml-2 flex items-center mt-0.5">
            {doPasswordsMatch && !togglePasswordConfirmedValidationErrors ? (
              <Image src={checkMarkIcon} alt="checkmark" height="12" width="12" />
            ) : (
              <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
            )}
            <a
              className={`ml-2 text-xs ${
                doPasswordsMatch && !togglePasswordConfirmedValidationErrors
                  ? 'text-green-600'
                  : `${isCheckout && !isMobile ? 'text-white' : 'text-red-600'}`
              }`}
            >
              {doPasswordsMatch && !togglePasswordConfirmedValidationErrors
                ? 'Passwords match'
                : "Those passwords didn't match. Try again."}
            </a>
          </div>
        )}
        <div className={`${isCheckout && !isMobile ? 'mt-8 mb-4 -ml-1.5' : 'mt-3.5 mb-8'}`}>
          <input
            className="absolute w-8 h-5"
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={() => handleRememberMeOnSubmit()}
          />
          <button type="button" className={`ml-10 ${isCheckout && !isMobile ? 'text-white' : ''}`}>
            {isCheckout ? 'Keep me logged in' : 'Remember me'}
          </button>
        </div>
        {isCheckout && !isMobile && (
          <p className="text-12 text-white mb-5">
            By proceeding you agree to our{' '}
            <a href="/terms-conditions" className="underline" target="_blank">
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a href="/privacy-policy" className="underline" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
        )}
        <ButtonPrimary onClick={(e) => handleSubmit(e)}>
          {`${isCheckout ? 'Sign Up' : 'Create Account'}`}
        </ButtonPrimary>
        {isCheckout && (
          <Link href={RoutingPath.GUEST_BILLING_DETAILS}>
            <a className="w-full flex justify-center items-center bg-white hover:bg-rp-light-gray-4 active:bg-rp-light-gray py-4 rounded-xl text-rp-primary font-rp-pn-semi-bold tracking-wide mt-4 h-12 border border-rp-primary d:border-0">
              Checkout As Guest
            </a>
          </Link>
        )}
      </div>
      {!isLoginButtonClicked && (
        <>
          {/* Divider */}
          <div
            className={`relative flex justify-center items-center ${isCheckout ? 'my-7' : 'my-5'}`}
          >
            <div className="w-full h-px top-1/2 bg-rp-gray-divider" />
            <div
              className={`${
                isCheckout && !isMobile ? 'bg-rp-primary-new text-white' : 'bg-white text-rp-gray'
              } absolute px-4 font-rp-pn-semi-bold`}
            >
              or
            </div>
          </div>
          {/* Alternative login options */}
          <div className="text-center">
            <ButtonLogin
              onClick={() => handleProviderLogin('facebook')}
              classes={`${isCheckout && !isMobile ? 'bg-white' : ''}`}
            >
              <div className="absolute left-10 mt-1.5">
                <Image src={facebookIcon} alt="facebook-icon" width="25" height="25" />
              </div>
              <span className="ml-5">Sign up with Facebook</span>
            </ButtonLogin>
            <ButtonLogin
              onClick={() => handleProviderLogin('google')}
              classes={`${isCheckout && !isMobile ? 'bg-white' : ''}`}
            >
              <div className="absolute left-10 mt-1.5">
                <Image src={googleIcon} alt="google-icon" width="25" height="25" />
              </div>
              <span className="ml-5">Sign up with Google</span>
            </ButtonLogin>
          </div>
        </>
      )}
    </>
  );
}

SignUp.defaultProps = defaultProps;
