/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState, ReactElement, ReactNode } from 'react';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import smoothscroll from 'smoothscroll-polyfill';
import { useRouter } from 'next/router';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Provider as CookiesConsentProvider } from '@hooks/useCookieConsent';
import { Provider as AuthFormProvider } from '@hooks/useAuthForm';
import { StateProvider } from '@context/store';
import { LoaderProvider } from '@context/LoaderContext';
import ErrorPopup from '@components/common/ErrorPopup/ErrorPopup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/globals.css';
import Layout from '@components/Layout/Layout';
import isRouteSearchBarEnabled from '@constants/isRouteSearchBarEnabled';
import ShowLoaderPath from '@constants/showLoaderPaths';
import ResortPassAnimation from '@components/common/ResortPassAnimation/ResortPassAnimation';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { isArray, isEmpty, pickBy } from 'lodash';
import { sojernPageView } from '@events/globals';
import { sendToGTM } from '@events/GTM';
import { v4 as uuidv4 } from 'uuid';
import Iterable from '@utils/Iterable/iterable';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { isNotDevEnvironment } from '@helpers/environment';
import { decodeString } from '@helpers/utmParams';
import { EventsProvider } from '@events/EventsProvider';
import useUnbounce from '@hooks/useUnbounce';
import RoutingPath from '@constants/routingPath';
import useIterableEmail from '@hooks/useIterableEmail';
import { SearchProvider } from '@context/SearchContext';
import { FeatureFlagProvider, ReferralCampaignFeatureFlagType } from '@context/FeatureFlagContext';
import { ExperimentProvider } from '@context/ExperimentContext';
import { DrawerProvider } from '@context/DrawerContext';

declare global {
  interface Window {
    friendbuyAPI?: any;
  }
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Hooks
  const router = useRouter();
  const searchBarEnabled = isRouteSearchBarEnabled(router.route, router.query);
  const { hideUnbounceBanner } = useUnbounce();
  const { getItem, setItem } = useSessionStorage();
  const [cookies] = useCookies();
  const iterableEmail = useIterableEmail();

  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const utmParams = getItem(sessionStorageKeys.UTM_PARAMS);
  const iterableStoredEmail = getItem(sessionStorageKeys.ITERABLE_EMAIL);
  const emailFromURL = router.query.email;

  const getLayout =
    Component.getLayout ??
    ((page) => (
      <Layout
        userLocation={null} // set user location as null for the moment (search-experiment)
        searchBarEnabled={searchBarEnabled}
      >
        {page}
      </Layout>
    ));

  const searchExperiment = (pageProps.searchExperiment as 'on' | null) ?? null;

  const getUTMParams = useCallback((query: NextParsedUrlQuery) => {
    const validUTMParams = pickBy(query, (value, key) => /^[utm_]/.test(key));

    return !isEmpty(validUTMParams) ? validUTMParams : '';
  }, []);

  // Store the "email" URL param in local storage for later use.
  useEffect(() => {
    if (emailFromURL) {
      setItem(
        sessionStorageKeys.ITERABLE_EMAIL,
        decodeString(isArray(emailFromURL) ? emailFromURL[0] : emailFromURL),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailFromURL, iterableStoredEmail]);

  // Store the "coupon" URL param in session storage for later use.
  useEffect(() => {
    const couponURL = router.query.coupon;

    if (couponURL) {
      setItem(
        sessionStorageKeys.COUPON,
        decodeString(isArray(couponURL) ? couponURL[0] : couponURL),
      );
    }
  }, [router.query, setItem]);

  useEffect(() => {
    // initializing polyfill to support smooth scrolling on all browsers
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY) {
      const updatedStripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
      setStripePromise(updatedStripePromise);
    }
  }, []);

  useEffect(() => {
    // ResortPass loader for certain pages
    const completeHandler = (path: string) => {
      if (
        path.indexOf('hotel-day-passes') === -1 &&
        path.indexOf('results') === -1 &&
        path.indexOf('cities') === -1
      ) {
        if (window) {
          window.scrollTo(0, 0);
        }
      }
      setIsLoading(false);
    };
    const startHandler = (path: string) => {
      let showLoading = false;
      ShowLoaderPath.forEach((item) => {
        if (path.indexOf(item) > -1) {
          showLoading = true;
        }
      });
      if (showLoading) setIsLoading(true);
    };
    router.events.on('routeChangeStart', startHandler);
    router.events.on('routeChangeComplete', completeHandler);
    return () => {
      router.events.off('routeChangeComplete', completeHandler);
      router.events.off('routeChangeStart', startHandler);
    };
  });

  /**
   * It grabs all utm_* params from url and stores them on local storage,
   *  for later use by purchase event.
   */
  useEffect(() => {
    if (!isEmpty(router.query)) {
      if (router.query) {
        // Get only utm_* params from url.
        const params = getUTMParams(router.query);

        if (params) {
          // Stores utm string in local storage
          setItem(sessionStorageKeys.UTM_PARAMS, JSON.stringify(params));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  // Create the sessionID unless it exists.
  useEffect(() => {
    if (!sessionID) {
      setItem(sessionStorageKeys.SESSION_ID, `${uuidv4()}-${Date.now().toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionID]);

  // Sojern page view events for non-listed pages.
  useEffect(() => {
    if (
      window.location.pathname !== '/' && // Homepage
      !window.location.pathname.startsWith('/hotels') && // DLP
      !window.location.pathname.startsWith('/users/guest-billing-detail') && // Cart page
      !window.location.pathname.startsWith('/results') && // SRP
      !window.location.pathname.startsWith('/hotel-day-passes') // SRP
    ) {
      sojernPageView(window.location.pathname, cookies.userInformation, sessionID);
    }
  }, [cookies.userInformation, sessionID, router.asPath]);

  // Send user.id or sessionID as user_id to GA4
  useEffect(() => {
    const user = cookies.userInformation;
    sendToGTM({ user_id: user ? user.id : sessionID });
  }, [cookies.userInformation, sessionID]);

  // Initialize Iterable
  useEffect(() => {
    (async () => {
      const user = cookies.userInformation;
      if ((user || sessionID) && isNotDevEnvironment()) {
        // take the utm_params from url if is the first load otherwise take it from local storage.
        let params;
        if (utmParams) {
          params = JSON.parse(utmParams);
        } else if (!isEmpty(router.query)) {
          if (router.query) {
            params = getUTMParams(router.query);
          }
        }

        if (router.isReady && iterableEmail) {
          window.Iterable = await Iterable.getInstance({
            user,
            email: iterableEmail,
            utmParams: params || '',
          });
        }
      }
    })();
  }, [cookies.userInformation, iterableEmail, getUTMParams, router, sessionID, utmParams]);

  // It listens to the Unbounce newsletter form and
  // uses the same email for following Iterable events.
  // Only if there's no Auth or Iterable stored email from URL.
  // That way we ensure it follows the email priority like:
  // Auth > URL param (or storedEmail) > Unbounce > session id placeholder email.
  useEffect(() => {
    const user = cookies.userInformation;

    const handleIframeMessage = async (event: any) => {
      // Check if the message is from the iframe and has the expected data
      if (event.data.type === 'inputChange') {
        const unbounceEmail = event.data.value;
        if (unbounceEmail && window.Iterable && !user && !iterableStoredEmail) {
          // Use email for following Iterable events
          setItem(sessionStorageKeys.ITERABLE_EMAIL, unbounceEmail);

          await window.Iterable.useANewEmail(unbounceEmail);
        }
      }
    };

    window.addEventListener('message', handleIframeMessage);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.userInformation, iterableStoredEmail, sessionID]);

  // Hide Unbounce banner on certain pages
  useEffect(() => {
    if (
      router.pathname.includes(RoutingPath.CHECKOUT_CONFIRMATION) ||
      router.pathname.includes(RoutingPath.CHECKOUT_SURVEYS)
    ) {
      hideUnbounceBanner();
    }
  }, [hideUnbounceBanner, router.pathname]);

  // Friendbuy Track Customer Script
  useEffect(() => {
    const user = cookies.userInformation;
    if (window.friendbuyAPI) {
      if (user) {
        window.friendbuyAPI.push([
          'track',
          'customer',
          {
            id: `${user.id}` || '',
            email: user.email || '',
            firstName: user.first_name || '',
            lastName: user.last_name || '',
          },
        ]);
      } else {
        window.friendbuyAPI.push(['logout']);
      }
    }
  }, [cookies.userInformation]);

  const referralCampaign = (pageProps.referralCampaign ||
    'original') as ReferralCampaignFeatureFlagType;

  return (
    <Elements stripe={stripePromise}>
      <StateProvider>
        <CookiesProvider>
          <SessionProvider session={pageProps.session} refetchInterval={0}>
            <CookiesConsentProvider>
              <EventsProvider>
                <FeatureFlagProvider value={{ referralCampaign }}>
                  <SearchProvider>
                    <DrawerProvider>
                      <ExperimentProvider searchExperiment={searchExperiment}>
                        <AuthFormProvider>
                          <LoaderProvider value={{ isLoading, setIsLoading }}>
                            {isLoading && <ResortPassAnimation />}
                            {!searchBarEnabled &&
                              getLayout(<Component {...pageProps} />, pageProps)}
                            {searchBarEnabled && <Component {...pageProps} />}
                          </LoaderProvider>
                        </AuthFormProvider>
                      </ExperimentProvider>
                    </DrawerProvider>
                  </SearchProvider>
                </FeatureFlagProvider>
              </EventsProvider>
            </CookiesConsentProvider>
          </SessionProvider>
        </CookiesProvider>
        <ErrorPopup />
      </StateProvider>
    </Elements>
  );
}

export default MyApp;
