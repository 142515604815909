/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RoutingPath from '@constants/routingPath';
import { useRouter } from 'next/router';
import useIsAndroid from '@hooks/useIsAndroid';
import {
  REFER_SKINNY_BANNER_CLICKED,
  SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED,
} from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import { marketingEmailSignupId } from '@constants/marketing';
import ArrowButton from './ArrowButton';
import BannerMessage from './BannerMessage';
import withPromoLogic, { PromoLogicProps } from '../withPromoLogic';

function SkinnyBanner({ referralCampaign, handleClick }: PromoLogicProps) {
  const router = useRouter();
  const path = router.pathname;
  const isAndroid = useIsAndroid();
  const { track } = useEvents();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const promoConfig = useMemo(() => {
    const androidDownloadLink = 'https://play.google.com/store/apps/details?id=com.resortpass.app';
    const iosDownloadLink = 'https://itunes.apple.com/app/id1557539492';

    return {
      original: [
        {
          main_text: 'Get Custom Offers',
          cta: {
            text: 'Enter Email',
            link: '#',
            id: marketingEmailSignupId,
          },
        },
        {
          main_text: 'Get the ResortPass App',
          cta: {
            text: 'Download',
            link: isAndroid ? androidDownloadLink : iosDownloadLink,
            id: 'app-download',
          },
        },
      ],
      sale: [
        {
          main_text: '',
          cta: {
            text: 'Give $20, Get $20',
            link: '/refer-skinnybanner',
            id: 'refer',
          },
        },
        {
          main_text: 'Get the ResortPass App',
          cta: {
            text: 'Download',
            link: isAndroid ? androidDownloadLink : iosDownloadLink,
            id: 'app-download',
          },
        },
      ],
    };
  }, [isAndroid]);

  const { main_text: mainText, cta } = promoConfig[referralCampaign][currentIndex];

  const handleNext = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % promoConfig[referralCampaign].length);
      setIsFading(false);
    }, 500);
  }, [promoConfig, referralCampaign]);

  const handlePrevious = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex(
        (prev) =>
          (prev - 1 + promoConfig[referralCampaign].length) % promoConfig[referralCampaign].length,
      );
      setIsFading(false);
    }, 500);
  }, [promoConfig, referralCampaign]);

  const handleCTAClick = () => {
    if (cta.id === marketingEmailSignupId) {
      handleClick();
    } else if (cta.id === 'app-download') {
      track(SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED, {});
      window.open(cta.link, '_blank');
    } else if (cta.id === 'refer') {
      track(REFER_SKINNY_BANNER_CLICKED, {});
      window.open(cta.link, '_self');
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (!isHovered) {
      intervalId = setInterval(handleNext, 7000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isHovered, handleNext]);

  useEffect(() => {
    if (referralCampaign) {
      setIsLoading(false);
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeoutId);
    };
  }, [referralCampaign]);

  return !isLoading &&
    path !== RoutingPath.GUEST_CHECKOUT &&
    path !== RoutingPath.GUEST_BILLING_DETAILS ? (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="bg-solar-surface-brand-shadow flex h-10 items-center justify-between w-full px-2 d:px-18px"
    >
      <ArrowButton direction="left" onClick={handlePrevious} />

      <BannerMessage isFading={isFading} message={mainText} cta={cta} onClick={handleCTAClick} />

      <ArrowButton direction="right" onClick={handleNext} />
    </div>
  ) : null;
}

export default withPromoLogic(SkinnyBanner, 'SkinnyBanner');
