import React, { createContext, useContext, useMemo } from 'react';

export type ReferralCampaignFeatureFlagType = 'original' | 'sale';

export type FeatureFlagsType = {
  referralCampaign: ReferralCampaignFeatureFlagType;
};

const FeatureFlagContext = createContext<FeatureFlagsType | undefined>(undefined);

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error('useFeatureFlagContext must be used within a FeatureFlagContext');
  }

  return context;
};

type FeatureFlagProviderProps = {
  children: React.ReactNode;
  value: {
    referralCampaign: ReferralCampaignFeatureFlagType;
  };
};

export function FeatureFlagProvider({ children, value }: FeatureFlagProviderProps) {
  const { referralCampaign } = value;

  const contextValue = useMemo(
    () => ({
      referralCampaign,
    }),
    [referralCampaign],
  );

  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>;
}
