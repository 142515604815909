const ADD_TO_CART = 'add_to_cart';
const ADD_TO_FAVORITES = 'add_to_favorites';
const APPLY_COUPON = 'apply_coupon';
const APPLY_FILTER = 'apply_filter';
const CAPTURE_EMAIL_VIEWED = 'capture_email_viewed';
const CATEGORY_SEARCH_HOMEPAGE_VIEWED = 'category_search_homepage_viewed';
const CATEGORY_SEARCH_SRP_VIEWED = 'category_search_srp_viewed';
const CLICK_CITIES_RELATED_CITY = 'click_cities_related_city';
const CLICK_DLP_POPULAR_HOTEL = 'click_dlp_popular_hotel';
const CLICK_LOGIN_BUTTON = 'click_login_button';
const COMPLETE_BILLING_DETAILS = 'complete_billing_detail';
const COMPLETE_CONTACT_DETAILS = 'complete_contact_details';
const COMPLETE_GUEST_DETAILS = 'complete_guest_details';
const COMPLETE_RESET_PASSWORD = 'complete_reset_password';
const CONTINUE_AS_GUEST = 'continue_as_guest';
const CONTINUE_AS_GUEST_CLICK = 'continue_as_guest_click';
const CONTINUE_WITH_EMAIL = 'continue_with_email';
const CONTINUE_WITH_OAUTH = 'continue_with_oauth';
const EDU_MODULE_CLICKED = 'edu_module_clicked';
const EMAIL_CAPTURED = 'email_captured';
const EXCLUDE_FROM_SEARCH_FEATURE_FLAG = 'excluded_from_search_feature_flag';
const FORGOT_PASSWORD_CLICK = 'Forgot_Password_Click';
const IM_FLEXIBLE_BUTTON_CLICKED = 'im_flexible_button_clicked';
const INVALID_CHECKOUT_FIELDS = 'invalid_checkout_fields';
const LEAVE_CHECKOUT = 'leave_checkout';
const LOG_OUT = 'log_out';
const LOGIN = 'login';
const LOGIN_BUTTON_CLICK = 'Login_Button_Click';
const OAUTH_BUTTON_CLICK = 'OAuth_Button_Click';
const POST_PURCHASE_NUDGE_VIEWED = 'post_purchase_nudge_viewed';
const PROCEED_TO_CHECKOUT = 'proceed_to_checkout';
const REMOVE_FROM_CART = 'remove_from_cart';
const REMOVE_FROM_FAVORITES = 'remove_from_favorites';
const RESET_LINK_BUTTON_CLIK = 'Reset_Link_Button_Click';
const REFER_FOOTER_CLICKED = 'refer_footer_clicked';
const REFER_NAV_CLICKED = 'refer_nav_clicked';
const REFER_SKINNY_BANNER_CLICKED = 'refer_skinny_banner_clicked';
const REFER_ORDER_CONFIRMATION_CLICKED = 'refer_order_confirmation_clicked';
const REFER_ACCOUNT_CLICKED = 'refer_account_clicked';
const SEARCH = 'search';
const SELECT_CALENDAR_PRODUCT_FILTER = 'select_calendar_product_filter';
const SELECT_HOTEL_CARD = 'select_hotel_card';
const SELECT_PRODUCT_CARD = 'select_product_card';
const SELECT_SEARCH_DATE = 'select_search_date';
const SELECT_SEARCH_LOCATION = 'select_search_location';
const SELECT_SEARCH_ON_VIEW_DLP = 'select_date_on_view_dlp';
const SHARE_BOOKING = 'share_booking';
const SHOW_EXPIRED_BOOKING_WINDOW = 'show_expired_booking_window';
const SHOW_PRODUCT_NO_LONGER_AVAILABLE = 'show_product_no_longer_available';
const SIGN_UP = 'sign_up';
const SIGN_UP_COMPLETED = 'sign_up_completed';
const SIGN_UP_INITIALIZED = 'sign_up_initiated';
const SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED = 'skinny_banner_app_download_cta_clicked';
const SPA_SECTION_CLICKED = 'spa_section_clicked';
const SPA_SECTION_CLICKED_SRP = 'spa_section_clicked_srp';
const START_RESET_PASSWORD = 'start_reset_password';
const START_SELECT_LOCATION = 'start_select_location';
const SUMMER_WEEKDAYS_SECTION_CLICKED = 'summer_weekdays_section_clicked';
const SURVEY_SKIPPED = 'survey_skipped';
const SURVEY_SUBMITTED = 'survey_submitted';
const SURVEY_VIEWED = 'survey_viewed';
const SWIPE_HOMEPAGE_PHOTO = 'swipe_homepage_photo';
const UNCHECK_TEXT_OFFERS = 'uncheck_text_offers';
const VIEW_ADVOCATE_PAGE = 'view_advocate_page';
const VIEW_AUTH = 'view_auth';
const VIEW_BOOKING = 'view_booking';
const VIEW_DETAIL_LISTING_PAGE = 'view_detail_listing_page';
const VIEW_DLP_REVIEWS = 'view_dlp_reviews';
const VIEW_FRIEND_PAGE = 'view_friend_page';
const VIEW_HOMEPAGE = 'view_homepage';
const VIEW_MY_REFERRALS = 'view_my_referrals';
const VIEW_PRODUCT_CARD = 'view_product_card';
const VIEW_SEARCH_RESULTS_PAGE = 'view_search_results_page';
const VIEW_SHOPPING_CART = 'view_shopping_cart';

interface IUseCaseMerchandisingEvents {
  VIEW_HOME_PAGE_NEAR_CITY_COLLECTION: string;
  HOME_PAGE_NEAR_CITY_COLLECTION_CLICK: string;
  VIEW_HOME_PAGE_POPULAR_HOTELS_COLLECTION: string;
  HOME_PAGE_POPULAR_HOTELS_COLLECTION_CLICK: string;
  HOME_PAGE_NEAR_CITY_COLLECTION_SEE_ALL_CLICK: string;
  VIEW_HOME_PAGE_NEW_AND_NOTABLE_COLLECTION: string;
  HOME_PAGE_NEW_AND_NOTABLE_COLLECTION_CLICK: string;
  HOME_PAGE_NEW_AND_NOTABLE_COLLECTION_SEE_ALL_CLICK: string;
  VIEW_HAND_PICKED_COLLECTION: string;
  HAND_PICKED_COLLECTION_CLICK: string;
}

export const UseCaseMerchandisingEvents: IUseCaseMerchandisingEvents = {
  VIEW_HOME_PAGE_NEAR_CITY_COLLECTION: 'view_home_page_near_city_collection',
  HOME_PAGE_NEAR_CITY_COLLECTION_CLICK: 'home_page_near_city_collection_click',
  VIEW_HOME_PAGE_POPULAR_HOTELS_COLLECTION: 'view_home_page_popular_hotels_collection',
  HOME_PAGE_POPULAR_HOTELS_COLLECTION_CLICK: 'home_page_popular_hotels_collection_click',
  HOME_PAGE_NEAR_CITY_COLLECTION_SEE_ALL_CLICK: 'home_page_near_city_collection_see_all_click',
  VIEW_HOME_PAGE_NEW_AND_NOTABLE_COLLECTION: 'view_home_page_new_and_notable_collection',
  HOME_PAGE_NEW_AND_NOTABLE_COLLECTION_CLICK: 'home_page_new_and_notable_collection_click',
  HOME_PAGE_NEW_AND_NOTABLE_COLLECTION_SEE_ALL_CLICK:
    'home_page_new_and_notable_collection_see_all_click',
  VIEW_HAND_PICKED_COLLECTION: 'view_hand_picked_collection',
  HAND_PICKED_COLLECTION_CLICK: 'hand_picked_collection_click',
};

// VIEW_NO_RESULTS_PAGE is used when the SRP renders no results
export const VIEW_NO_RESULTS_PAGE = 'view_no_results_page';

// VIEW_HOTEL_CARD is used when a user views a hotel card on the SRP
export const VIEW_HOTEL_CARD = 'view_hotel_card';

// APPLY_PRODUCT_CATEGORY is used when a user selects a product category filter
// before conducting a search
export const APPLY_PRODUCT_CATEGORY = 'apply_product_category';

// START_SELECT_SEARCH_LOCATION is used when a user starts to select a typeahead search.
// This should only be fired once.
export const START_SELECT_SEARCH_LOCATION = 'start_select_search_location';

// CANCEL_SEARCH_LOCATION is used when a user clicks the cancel button on the datepicker
export const CANCEL_SEARCH_DATE = 'cancel_search_date';

// CANCEL_SEARCH_FILTER is used when a user clicks the close button  on the filter drawer
export const CANCEL_SEARCH_FILTER = 'cancel_search_filter';

export {
  ADD_TO_CART,
  ADD_TO_FAVORITES,
  APPLY_COUPON,
  APPLY_FILTER,
  CATEGORY_SEARCH_HOMEPAGE_VIEWED,
  CATEGORY_SEARCH_SRP_VIEWED,
  CAPTURE_EMAIL_VIEWED,
  CLICK_CITIES_RELATED_CITY,
  CLICK_DLP_POPULAR_HOTEL,
  CLICK_LOGIN_BUTTON,
  COMPLETE_BILLING_DETAILS,
  COMPLETE_CONTACT_DETAILS,
  COMPLETE_GUEST_DETAILS,
  COMPLETE_RESET_PASSWORD,
  CONTINUE_WITH_EMAIL,
  CONTINUE_WITH_OAUTH,
  CONTINUE_AS_GUEST,
  CONTINUE_AS_GUEST_CLICK,
  EDU_MODULE_CLICKED,
  EMAIL_CAPTURED,
  EXCLUDE_FROM_SEARCH_FEATURE_FLAG,
  FORGOT_PASSWORD_CLICK,
  IM_FLEXIBLE_BUTTON_CLICKED,
  INVALID_CHECKOUT_FIELDS,
  LEAVE_CHECKOUT,
  LOG_OUT,
  LOGIN_BUTTON_CLICK,
  LOGIN,
  OAUTH_BUTTON_CLICK,
  PROCEED_TO_CHECKOUT,
  POST_PURCHASE_NUDGE_VIEWED,
  REMOVE_FROM_CART,
  REMOVE_FROM_FAVORITES,
  RESET_LINK_BUTTON_CLIK,
  REFER_FOOTER_CLICKED,
  REFER_NAV_CLICKED,
  REFER_SKINNY_BANNER_CLICKED,
  REFER_ORDER_CONFIRMATION_CLICKED,
  REFER_ACCOUNT_CLICKED,
  SEARCH,
  SELECT_CALENDAR_PRODUCT_FILTER,
  SELECT_HOTEL_CARD,
  SELECT_PRODUCT_CARD,
  SELECT_SEARCH_DATE,
  SELECT_SEARCH_LOCATION,
  SELECT_SEARCH_ON_VIEW_DLP,
  SHARE_BOOKING,
  SHOW_EXPIRED_BOOKING_WINDOW,
  SHOW_PRODUCT_NO_LONGER_AVAILABLE,
  SIGN_UP_INITIALIZED,
  SIGN_UP_COMPLETED,
  SIGN_UP,
  SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED,
  SPA_SECTION_CLICKED_SRP,
  SPA_SECTION_CLICKED,
  START_RESET_PASSWORD,
  START_SELECT_LOCATION,
  SUMMER_WEEKDAYS_SECTION_CLICKED,
  SURVEY_SKIPPED,
  SURVEY_SUBMITTED,
  SURVEY_VIEWED,
  SWIPE_HOMEPAGE_PHOTO,
  UNCHECK_TEXT_OFFERS,
  VIEW_BOOKING,
  VIEW_DETAIL_LISTING_PAGE,
  VIEW_DLP_REVIEWS,
  VIEW_HOMEPAGE,
  VIEW_PRODUCT_CARD,
  VIEW_SEARCH_RESULTS_PAGE,
  VIEW_SHOPPING_CART,
  VIEW_AUTH,
  VIEW_ADVOCATE_PAGE,
  VIEW_FRIEND_PAGE,
  VIEW_MY_REFERRALS,
};
