const exploreLinks = [
  {
    label: 'Hotel Partner Login',
    title: 'Login to the Hotel Partner Portal',
    href: `${process.env.NEXT_PUBLIC_RAILS_APP_URL}/hotel_users/sign_in`,
    target: '_self',
  },
  {
    label: 'Blog',
    title: 'Read our latest blog posts',
    href: 'https://www.resortpass.com/blog/',
    target: '_blank',
  },
  {
    label: 'Give $20, Get $20',
    title: 'Give $20, Get $20',
    href: '/refer-footer',
    target: '_self',
  },
  {
    label: 'Help & FAQs',
    title: 'Open Help & FAQs in a new window',
    href: 'https://help.resortpass.com/hc/en-us',
    target: '_blank',
  },
  {
    label: 'Reviews',
    title: 'Read customer reviews',
    href: '/reviews',
    target: '_self',
  },
  {
    label: 'Media',
    title: 'View our media gallery',
    href: 'https://media.resortpass.com/',
    target: '_self',
  },
  {
    label: 'Mastercard cardholders',
    title: 'Mastercard cardholders',
    href: '/mastercard',
    target: '_self',
  },
];

const aboutLinks = [
  {
    label: 'About Us',
    href: 'https://partners.resortpass.com/about-us',
  },
  {
    label: 'Careers',
    href: 'https://boards.greenhouse.io/resortpass',
  },
  {
    label: 'Become a Hotel Partner',
    href: 'https://partners.resortpass.com/',
  },
  {
    label: 'Affiliate Program',
    href: 'https://affiliates.resortpass.com/',
  },
  {
    label: 'Creator Program',
    href: 'http://creators.resortpass.com/',
  },
  {
    label: 'Your Privacy Rights',
    href: '/your-privacy-rights',
  },
  {
    label: 'Terms and Conditions',
    href: '/terms-conditions',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    label: 'Accessibility',
    href: '/accessibility-statement',
  },
];

export { exploreLinks, aboutLinks };
