import React, { ComponentType, useCallback } from 'react';
import { useEvents } from '@events/EventsProvider';
import { CAPTURE_EMAIL_VIEWED } from '@constants/amplitudeEvents';
import { useRouter } from 'next/router';
import {
  ReferralCampaignFeatureFlagType,
  useFeatureFlagContext,
} from '@context/FeatureFlagContext';

export type PromoLogicProps = {
  referralCampaign: ReferralCampaignFeatureFlagType;
  handleClick: () => void;
};

type Components =
  | 'CaptureEmailDLPInLine'
  | 'CaptureEmailFooter'
  | 'CaptureEmailNavMenuItem'
  | 'HomePageMarketingModule'
  | 'SkinnyBanner'
  | 'SRPMarketingModule';

export default function withPromoLogic<T extends object>(
  WrappedComponent: ComponentType<T & PromoLogicProps>,
  source: Components,
) {
  return function ComponentWithPromoLogic(props: T) {
    const router = useRouter();
    const { track } = useEvents();
    const { referralCampaign } = useFeatureFlagContext();

    const handleClick = useCallback(() => {
      if (source !== 'HomePageMarketingModule' && source !== 'SRPMarketingModule') {
        // Add a hashtag to the URL without causing a page reload or re-render.
        // This is used to track which MarketingModule was clicked.
        router.push(
          {
            pathname: router.pathname,
            query: { ...router.query, 'marketing-module': source },
          },
          undefined,
          { shallow: true, scroll: false },
        );
      }

      track(CAPTURE_EMAIL_VIEWED, { source });
    }, [router, track]);

    return (
      <WrappedComponent {...props} handleClick={handleClick} referralCampaign={referralCampaign} />
    );
  };
}
